<template>
    <div class="c-form__group" :class="[{ 'c-form__group--has-error' : inputError }, { 'c-form__group--selected' : selected }, { 'c-form__group--filled' : filled }]">
        <label :for="comp.settings.id" class="c-form__label">{{
            comp.settings.label }}</label>
        <textarea cols="30" rows="10" class="c-form__textarea" :name="comp.settings.id" :id="comp.settings.id" :placeholder="comp.settings.placeholder" @input="emitInput($event.target.value)" v-model="value"  :class="[ { 'c-form__textarea--has-error' : inputError }, { 'c-form__textarea--selected' : selected }, { 'c-form__textarea--filled' : filled } ]" @focus="selected = true" @blur="selected = false"></textarea>
        <p class="c-form__error" v-if="inputError">{{ inputError }}</p>
    </div>
</template>

<script>
  import {EventBus} from "@/event-bus";

  export default {
    name: 'Comp_textarea',
    props: {
      comp: Object,
      errors: Array
    },

    data () {
      return {
        value: null,
        selected: false,
        filled: false
      }
    },

    created () {
      EventBus.$on('form sent', () => {
        this.value = ''
      })
    },

    methods: {
      emitInput (value) {
        EventBus.$emit('textarea', value)

        if (this.value.length >= 1) {
          this.filled = true
        } else {
          this.filled = false
        }
      }
    },

    computed: {
      inputError () {
        const matchingError = this.errors.find(e => e.type === this.comp.settings.id)
        if (matchingError && this.errors !== undefined) {
          return matchingError.message
        } else {
          return undefined
        }
      }
    }
  }
</script>
